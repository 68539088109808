/// Menu
import MetisMenu from "metismenujs";
import React, { Component } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {
  }
    render() {
        return (
            <div className="mm-wrapper">
                <ul className="metismenu" ref={(el) => (this.el = el)}>
                    {this.props.children}
                </ul>
            </div>
        );
    }
}

class SideBar extends Component {
  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
  }
  state = {
    loveEmoji: false,
  };
  render() {
    /// Path
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];
    
    /// Active menu
    let dashBoard = [
        ""
      ],

      app = [
        "app-profile",
        "app-calender",
        "email-compose",
      ],

      jobs =[
        "jobs",
        "add-jobs"
      ],

      chats =[
        "chats"
      ],
      interview = [
        "schedule"
      ],
      cvParsing =[
        "parse-cv"
      ],
      applications = [
        "applications"
      ],
      users=[
        "add-admin"
      ],
      platformUser=[
        "all-users"
      ],
      feedbacks =[
        "feedbacks"
      ]

     

    return (
      <div className="deznav">
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            <li className={`${dashBoard.includes(path) ? "mm-active" : ""}`}>
              <Link className={`${path === "" ? "mm-active" : ""}`} to="/"  onClick={() => this.props.onClick3()}>
              <i className="flaticon-381-folder"></i>
                <span className="nav-text">Dashboard</span>
              </Link>
            </li>

            <li className={`${platformUser.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="flaticon-381-user"></i>
                <span className="nav-text">Users</span>
              </Link>
              <ul >
                <li>
                  <Link className={`${path === "all-users" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to="/all-users">Users</Link>
                </li>
               

              </ul>
            </li>


            <li className={`${jobs.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="flaticon-381-note"></i>
                <span className="nav-text">Jobs</span>
              </Link>
              <ul >
                <li>
                  <Link className={`${path === "jobs" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to="/jobs">Jobs</Link>
                </li>
                <li>
                  <Link className={`${path === "add-jobs" ? "mm-active" : ""}`} onClick={() => this.props.onClick3()} to="/add-jobs">Post Jobs</Link>
                </li>

                <li>
                  <Link className={`${path === "category" ? "mm-active" : ""}`} onClick={() => this.props.onClick3()} to="/category">Job Categories</Link>
                </li>

              </ul>
            </li>


           
            <li className={`${interview.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="flaticon-381-pencil"></i>
                <span className="nav-text">Interview</span>
              </Link>
              <ul >
                <li>
                  <Link className={`${path === "schedule" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to="/schedules">Schedules</Link>
                </li>
               

              </ul>
            </li>

            <li className={`${applications.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="flaticon-381-book"></i>
                <span className="nav-text">Applications</span>
              </Link>
              <ul >
                <li>
                  <Link className={`${path === "applications" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to="/applications">Applicants</Link>
                </li>
               

              </ul>
            </li>


            <li className={`${cvParsing.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="flaticon-381-pencil"></i>
                <span className="nav-text">Cv Parsing</span>
              </Link>
              <ul >
                <li>
                  <Link className={`${path === "parse-cv" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to="/parse-cv">Parse CV</Link>
                </li>
               

              </ul>
            </li>


              
            <li className={`${chats.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="flaticon-381-controls"></i>
                <span className="nav-text">Chats</span>
              </Link>
              <ul >
                <li>
                  <Link className={`${path === "chats" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to="/chats">Chat</Link>
                </li>
                

              </ul>
            </li>


               
            <li className={`${users.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="flaticon-381-user"></i>
                <span className="nav-text">Admin Management</span>
              </Link>
              <ul >
                <li>
                  <Link className={`${path === "users" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to="/add-admin">Add Admin</Link>
                </li>
                

              </ul>
            </li>

                
            <li className={`${feedbacks.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="flaticon-381-user"></i>
                <span className="nav-text">Feedbacks</span>
              </Link>
              <ul >
                <li>
                  <Link className={`${path === "feedbacks" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to="/feedbacks">Feedbacks</Link>
                </li>
                

              </ul>
            </li>



           
           
          </MM>
			
		
			</PerfectScrollbar>
      </div>
    );
  }
}

export default SideBar;
