import React, { useEffect, useState } from 'react'
import io from "socket.io-client"
import { getConvo } from '../../../features/profileSlice'
import "./Chat.css";
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../../Spinner'
import ChatRight from './ChatRight';


const MainChat = () => {
  const { convo } = useSelector((state) => state.profile)
  const dispatch = useDispatch()
  const { admin } = useSelector((state) => state.auth)
  const email = "chatril@resourceintermediaries.org"
  const[buddy, setBuddy] = useState(null)

  const handleChat = (value)=>{
    setBuddy(value)
    console.log(buddy)
  }

  useEffect(() => {
    dispatch(getConvo(email))
  }, [dispatch])
  if (!convo)
    return (<Spinner />)
  return (
    <>
    <div className='row'>
      <div className='col-md-4'>
    <aside>
		<header>
			<h3 className='text-white'>Chats</h3>
		</header>
		<ul>
    {convo.map((items) => (
      <div key={items.id}>
      <li className='px-5' onClick={()=>handleChat(items.recieverId)}>
					<h2>{items.recieverId}</h2>
			</li>
      </div>
  ))}
			
		</ul>
	</aside>
  </div>
  <div className='col-md-8 bg-light'>
    {!buddy ? <h3>Click on any chat on the left hand side to start chatting</h3> :
  <ChatRight buddy={buddy} />
  }
  </div>
</div>
        </>
        
  )
}

export default MainChat