import React, { useEffect, useRef, useState } from 'react'
import "./Chat.css";
import { useDispatch, useSelector } from 'react-redux';
import { getMessages, addMessage } from '../../../features/chatSlice'
import moment from 'moment';
import Spinner from '../../../Spinner';
import io from "socket.io-client"
import ScrollToBottom from 'react-scroll-to-bottom';

const ChatRight = ({ buddy }) => {
  const scrollRef = useRef()

  const [message, setMessage] = useState()
  const [arrivalMessage, setArrivalMessage] = useState(null)
  const [chatHistory, setChatHistory] = useState(null);
  const socket = useRef()

  const email = "chatril@resourceintermediaries.org"
  const { chats } = useSelector((state) => state.chat)

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" })
  }, [chatHistory])

  //useEffect for socket
  useEffect(() => {
    if (buddy !== '') {
      socket.current = io("https://ats.nochsolutions.com.ng", {
        withCredentials: false
      })
      //  socket.current=io("http://localhost:9000")
      // socket.current.emit("addUser", email)
    }
  }, [buddy])


  //receive socket message
  useEffect(() => {
    if (socket.current) {
      socket.current.on("msg-received", (msg) => {
        console.log(msg)
        setArrivalMessage(msg)
      })
    }
  }, [])

  //fetch messages
  const dispatch = useDispatch()
  useEffect(() => {
    let data = {
      senderId: buddy,
      recieverId: email
    }
    dispatch(getMessages(data))
  }, [dispatch, buddy])


  //set payload in local state
  useEffect(() => {
    if (chatHistory != chats) {
      setChatHistory(chats)
    }
  }, [chats])

  //submit messages
  const handleMessage = () => {
    let data = {
      senderId: email,
      recieverId: buddy,
      text: message
    }
    if (message !== '') {
      socket.current.emit("send-msg", {
        recieverId: buddy,
        senderId: email,
        text: message
      })
      setChatHistory(chatHistory.concat(data))
      dispatch(addMessage(data))
      setMessage('');
    }

  }




  //fetch socket messages
  useEffect(() => {
    if (arrivalMessage) {
      setChatHistory(chatHistory.concat(arrivalMessage))
    }
  }, [arrivalMessage])

  console.log(chats)
  if (!chatHistory)
    return (<Spinner />)
  return (
    <div>
      <main>

        <header className='bg-light'>
          <img src="/images/avartar.png" alt="" />
          <div>
            <h2>Chat with {buddy}</h2>
          </div>
        </header>
        <ul id="chat">
          {chatHistory.map((item) => (
            <div ref={scrollRef}>
              {item.senderId === email
                ?
                <li class="me" key={item.id}>
                  <div class="entete">
                    <h3>{moment(item.createdAt).fromNow()}</h3>
                    <h2>{email}</h2>
                    <span class="status blue"></span>
                  </div>
                  <div class="triangle"></div>
                  <div class="message">
                    {item.text}
                  </div>
                </li>
                :
                <li class="you">
                  <div class="entete">
                    <span class="status green"></span>
                    <h3>{buddy}</h3>
                    <h2>{moment(item.createdAt).fromNow()}</h2>
                  </div>
                  <div class="triangle"></div>
                  <div class="message">
                    {item.text}
                  </div>
                </li>}
            </div>

          ))}



        </ul>
        <footer>
          <textarea placeholder="Type your message" onChange={(e) => setMessage(e.target.value)} value={message}></textarea>
          <button className='btn btn-block btn-danger' onClick={handleMessage}>Send</button>
        </footer>

      </main>
    </div>
  )
}

export default ChatRight