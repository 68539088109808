import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFeedbacks } from '../../../features/profileSlice'
import Spinner from '../../../Spinner'

const Feedbacks = () => {
    const dispatch = useDispatch()
    const { feedback } = useSelector((state)=>state.profile)

    useEffect(()=>{
      window.scrollTo(0, 0);
        async function fetchData(){
          await dispatch(getFeedbacks())  
        }
      fetchData()  
    },[dispatch])

    if(!feedback)
    return(<Spinner />)

    console.log(feedback)
    var x =0;
  return (
    <>
     <h4 className="fs-20 text-black display-5 fw-bold">
                                 All Feedbacks
                              </h4>

                                    <table className="table shadow-hover table-striped card-table w-100" >
                                       
                                         <thead>
                                          <tr>
                                            <th>S/N</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Title</th>
                                            <th>Message</th>
                                            </tr>
                                         </thead>
                                         <tbody>
                                          {feedback.data.rows.map((items)=>(
                                            <tr key={items.id}>
                                              <td>{++x}</td>
                                              <td>{items.fid.lastName} {items.fid.firstName}</td>
                                              <td>{items.fid.email}</td>
                                              <td>{items.messageTitle}</td>
                                              <td>{items.message}</td>
                                              <td>
                                               
                                                
                                              </td>
                                            </tr>
                                          ))}
                                         </tbody>
                                        
                                         
                                      
                                      
                                    </table>
    </>
  )
}

export default Feedbacks