import moment from 'moment'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { getInterview, singleInterview, editInterview, cancelInterview } from '../../../features/jobSlice'
import Spinner from '../../../Spinner'
import { CSVLink } from 'react-csv'
import ReactPaginate from 'react-paginate'

const Schedule = () => {
 
    const {interview, myInterview} = useSelector((state)=>state.job)
    var x = 0
     const dispatch= useDispatch()
     const [currentPage, setCurrentPage] = useState(1);

     const handlePageChange = ({ selected }) => {
      setCurrentPage(selected)
    };

     useEffect(()=>{
      async function fetchInter(){
       await  dispatch(getInterview({page:currentPage}))
      }
       fetchInter() 
     },[dispatch, currentPage])

     console.log(interview)

     const [show, setShow] = useState(false)

     const handleClose = () => setShow(false)
     

     const [userEmail, setUserEmail] = useState('')
     const [userId, setUserId] = useState('')
     const [jobId, setJobId] = useState('')
     const [address, setAddress] = useState('')
     const [dateIn, setDate] = useState('')
     const [timeStart, setTime] = useState('')
     const [notes, setNotes] = useState('')

     const handleShow = (id) => {
      dispatch(singleInterview(id))
      if(!myInterview){
        return 'loading...'
      }else{
      setUserEmail(myInterview[0].userEmail)
      setUserId(myInterview[0].userId)
      setJobId(myInterview[0].jobId)
      setAddress(myInterview[0].address)
      setDate(myInterview[0].dateIn)
      setTime(myInterview[0].timeStart)
      setNotes(myInterview[0].notes)
      setShow(true)
      }
      
    }
     
    const reschedule = (id)=>{
      let data = {
        userEmail,
        jobId,
        userId,
        id,
        timeStart,
        dateIn,
        notes,
        address
      }
      dispatch(editInterview(data)).unwrap().then(()=>{
        toast.success('Interview Rescheduled')
      }).catch(toast.error)
      setShow(false)

    }


    const cancelInterview =(e, userId, jobId, id)=>{
      e.preventDefault()
     let data={
      userId,
      jobId,
      id
     }
     dispatch(cancelInterview(data)).unwrap().then(()=>{
      toast.success('Interview Invite canceled and notification sent to user')
     }).catch(toast.error)

    }

    

    if(!interview)
    return(<Spinner />)

    // console.log(applicants)

  return (
    <>
    <h4 className="fs-20 text-black display-5 fw-bold">
                                 All Interview Schedule
                              </h4>

                              <CSVLink data={interview.data.rows} className='btn btn-success float-right mb-3'>Export to CSV </CSVLink>

                                    <table className="table shadow-hover table-striped card-table w-100" >
                                       
                                         <thead>
                                          <tr>
                                            <th>S/N</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Address/Link</th>
                                            <th>Job Title</th>
                                            <th>Date</th>
                                            <th>Time</th>
                                            <th>Notes</th>
                                            <th>Actions</th>
                                            </tr>
                                         </thead>
                                         <tbody>
                                          {interview.data.rows.map((items)=>(
                                            <tr key={items.id}>
                                              <td>{++x}</td>
                                              <td>{!items.ids  ? '' :items.ids.lastName} {!items.ids ? '' :items.ids.firstName }</td>
                                              <td>{!items.ids ? '' : items.ids.email}</td>
                                              <td>{items.address}</td>
                                              <td>{!items.jobids ? '' : items.jobids.jobTitle}</td>
                                              <td>{!items.jobids ? '' : items.dateIn}</td>
                                              <td>{items.timeStart}</td>
                                              <td>{items.notes}</td>
                                              <td>
                                                <div className='btn-group'>
                                                  <i className="fa fa-edit text-danger"  onClick={()=>handleShow(items.id)}></i>  
                                                  {/* <button  className='btn-sm btn-danger mx-2' onClick={()=>cancelInterview(items.jobId, items.userId, items.id)}><i className="fa fa-times"></i> Cancel</button> */}
                                                  
                                                </div>
                                                
                                              </td>
                                            </tr>
                                          ))}
                                         </tbody>
                                        
                                         
                                      
                                      
                                    </table>
                                    <ReactPaginate
                pageCount={interview.totalPages}
                previousLabel={'Previous'}
                nextLabel={'Next'}
                breakLabel={'...'}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                onPageChange={handlePageChange}
                forcePage={currentPage}
                previousClassName={'page-item'}
                nextClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextLinkClassName={'page-link'}
                containerClassName={'pagination'}
                activeClassName={'active'}
                pageLinkClassName={'page-link'} />

                                    <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Schedule Interview for {userEmail}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            
              <div className='col-md-6 col-sm-12'>
                <div className='form-group'>
                <input type="date" className='form-control' value={moment(dateIn).format('YYYY-MM-DD')} onChange={(e)=>setDate(e.target.value)} />

              </div>
              </div>

              <div className='col-md-6 col-sm-12'>
              <div className='form-group'>
                <input type="time" className='form-control' value={timeStart} onChange={(e)=>setTime(e.target.value)} />

              </div>
            </div>

            <div className='col-md-6 col-sm-12'>
              <div className='form-group'>
                <input type="text" className='form-control' value={address} placeholder='Interview link or Address' onChange={(e)=>setAddress(e.target.value)} />
              </div>
            </div>

            <div className='col-md-6 col-sm-12'>
              <div className='form-group'>
                <input type="text" className='form-control' value={notes} placeholder='Extra Information' onChange={(e)=>setNotes(e.target.value)} />
              </div>
            </div>
            <input type="hidden" value={userEmail} onChange={(e)=>setUserEmail(e.target.value)} />
            <input type="hidden" value={jobId} onChange={(e)=>setJobId(e.target.value)} />
            <input type="hidden" value={userId} onChange={(e)=>setUserId(e.target.value)} />
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>reschedule(myInterview[0].id)} >
            Re-Schedule
          </Button>
        </Modal.Footer>
      </Modal>         
    </>
  )
}

export default Schedule