import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCategory, deleteCategory, addCategory} from '../../../features/jobSlice'
import Spinner from '../../../Spinner'
import { toast } from 'react-toastify'
import { Button, Modal } from 'react-bootstrap'
import { CSVLink } from 'react-csv'
import ReactPaginate from 'react-paginate'

const Category = () => {
  
    const {category} = useSelector((state)=>state.job)
    const [hasExecuted, setHasExecuted] = useState(false)
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleOpen = () => setShow(true)
    const [categories, setCategory] = useState('')
    const [currentPage, setCurrentPage] = useState(1);


    var x = 0
     const dispatch= useDispatch()
    
     const handleDelete = (id) => {
        dispatch(deleteCategory(id)).unwrap().then(()=>{
            toast.error('category deleted')
            setHasExecuted(!hasExecuted)
        }).catch(toast.error)
     }

     const handleCategory = () =>{
        let data={
            category:categories
        }
        dispatch(addCategory(data)).unwrap().then(()=>{
            toast.success('category added')
            setShow(false)
            setHasExecuted(!hasExecuted)
        }).catch(toast.error)

     }

     const handlePageChange = ({ selected }) => {
      setCurrentPage(selected)
    };

     
     useEffect(()=>{
      dispatch(getCategory())
   },[dispatch, hasExecuted, currentPage])



    if(!category)
    return(<Spinner />)


  return (
    <>
    
    <h4 className="fs-20 text-black display-5 fw-bold">
                                 All Category
                              </h4>
                              <div>
        <Button className='btn btn-warning mb-3 float-right' onClick={handleOpen}>Add Category</Button> 
    </div>
    <CSVLink data={category.data.rows} className='btn btn-success mb-3'>Export to CSV </CSVLink>

                                    <table className="table shadow-hover table-striped card-table w-100" >
                                       
                                         <thead>
                                          <tr>
                                            <th>S/N</th>
                                            <th>Name</th>
                                            
                                            </tr>
                                         </thead>
                                         <tbody>
                                          {category== undefined || null ? 'No category added yet' :  category.data.rows.map((items)=>(
                                            <tr key={items.id}>
                                              <td>{++x}</td>
                                              <td>{items.category}</td>
                                              <td>
                                                <div className='btn-group'>
                                                 
                                                  <i className='fa fa-trash text-danger mx-2 my-2' onClick={()=>handleDelete(items.id)} ></i>
                                                  
                                                </div>
                                                
                                              </td>
                                            </tr>
                                          ))}
                                         </tbody>
                                        
                                         
                                      
                                      
                                    </table>

                                    <ReactPaginate
                pageCount={category.totalPages}
                previousLabel={'Previous'}
                nextLabel={'Next'}
                breakLabel={'...'}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                onPageChange={handlePageChange}
                forcePage={currentPage}
                previousClassName={'page-item'}
                nextClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextLinkClassName={'page-link'}
                containerClassName={'pagination'}
                activeClassName={'active'}
                pageLinkClassName={'page-link'} />

                                    <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Category </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            
              <div className='col-md-12 col-sm-12'>
                <div className='form-group'>
                <input type="text" placeholder='Category' className='form-control' onChange={(e)=>setCategory(e.target.value)}/>
              </div>
              </div>

             
        
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCategory}>
            Add Category
          </Button>
        </Modal.Footer>
      </Modal>
                                 
    </>
  )
}

export default Category