import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllUser } from '../../../features/jobSlice'
import { CSVLink } from "react-csv"
import Spinner from '../../../Spinner'
import ReactPaginate from 'react-paginate'
import { deleteUser, blackListUser, removeBlackListUser } from '../../../features/profileSlice'
import { toast } from 'react-toastify'

const AllUsers = () => {

  const { users } = useSelector((state) => state.job)
  const [currentPage, setCurrentPage] = useState(1);
  const [hasExecuted, setHasExecuted] = useState(false);
  var x = 0
  const dispatch = useDispatch()

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected)
  };

  const handleDeleteUser = (ids) => {
    dispatch(deleteUser(ids)).unwrap().then((resp) => {
      setHasExecuted(!hasExecuted)
      toast.success(resp.message)
    }).catch(toast.error)
  }

  const handleBlackList = (id1) => {
    dispatch(blackListUser(id1)).unwrap().then((resp) => {
      setHasExecuted(!hasExecuted)
      toast.success(resp.message)
    }).catch(toast.error)
  }

  const handleRemoveBlackList = (id2) => {
    dispatch(removeBlackListUser(id2)).unwrap().then((resp) => {
      setHasExecuted(!hasExecuted)
      toast.success(resp.message)
    }).catch(toast.error)
  }



  useEffect(() => {
    dispatch(getAllUser({ page: currentPage }))
  }, [dispatch, currentPage, hasExecuted])



  if (!users)
    return (<Spinner />)


  return (
    <>
      <h4 className="fs-20 text-black display-5 fw-bold">
        All Users
      </h4>

      <CSVLink data={users.data.rows} className='btn btn-success float-right mb-3'>Export to CSV </CSVLink>


      <table className="table shadow-hover table-striped card-table w-100" >

        <thead>
          <tr>
            <th>S/N</th>
            <th>Name</th>
            <th>Email</th>
            <th>Industry</th>
            <th>Login Method</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {users.data.rows.map((items) => (
            <>
              <tr key={items.id}>
                <td>{++x}</td>
                <td>{items.lastName} {items.firstName}</td>
                <td>{items.email}</td>
                <td>{items.industry}</td>
                <td>{items.ids == null || '' ? '' : items.ids.password == 0 ? 'Social Login' : 'Password Login'}</td>
                <td>
                  <div className='btn-group'>
                    <a href={`/userProfile?id=${items.id}`} className='mx-2 my-2'><i className='fa fa-eye text-warning'></i></a>
                    {'  '}
                    <i className='fa fa-trash text-danger mx-2 my-2' onClick={() => handleDeleteUser(items.ids.id)}></i>
                    {'  '}
                    {items.ids == null || '' ? '' : items.ids.blackListed === 0 ? (
                      <i className='fa fa-window-close text-danger mx-2 my-2' onClick={() => handleBlackList(items.id)}></i>
                    ) : (
                      <i className='fa fa-window-close text-warning mx-2 my-2' onClick={() => handleRemoveBlackList(items.id)}></i>
                    )}
                  </div>


                </td>
              </tr>

            </>
          ))}
        </tbody>




      </table>

      <ReactPaginate
        pageCount={users.totalPages}
        previousLabel={'Previous'}
        nextLabel={'Next'}
        breakLabel={'...'}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        onPageChange={handlePageChange}
        forcePage={currentPage}
        previousClassName={'page-item'}
        nextClassName={'page-item'}
        previousLinkClassName={'page-link'}
        nextLinkClassName={'page-link'}
        containerClassName={'pagination'}
        activeClassName={'active'}
        pageLinkClassName={'page-link'} />

    </>
  )
}

export default AllUsers