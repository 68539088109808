import React,{useState} from "react";
import { logout } from "../../../features/authSlice";

import { Link, useHistory } from "react-router-dom";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Image
import profile from "../../../images/avartar.png";
import avatar from "../../../images/avatar/1.jpg";
import { Dropdown } from "react-bootstrap";
import LogoutPage from './Logout';
import { useDispatch, useSelector } from "react-redux";

const Header = ({ onNote, toggle, onProfile, onNotification, onClick }) => {
  const dispatch = useDispatch()
  const navigate = useHistory()
  const {admin} = useSelector((state)=>state.auth)
  const logoutUser = () =>{
    dispatch(logout()).unwrap().then(()=>{
      navigate('/')
    })
  }
  var path = window.location.pathname.split("/");
  var name = path[path.length - 1].split("-");
  var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
  var finalName = filterName.includes("app")
    ? filterName.filter((f) => f !== "app")
    : filterName.includes("ui")
    ? filterName.filter((f) => f !== "ui")
    : filterName.includes("uc")
    ? filterName.filter((f) => f !== "uc")
    : filterName.includes("basic")
    ? filterName.filter((f) => f !== "basic")
    : filterName.includes("jquery")
    ? filterName.filter((f) => f !== "jquery")
    : filterName.includes("table")
    ? filterName.filter((f) => f !== "table")
    : filterName.includes("page")
    ? filterName.filter((f) => f !== "page")
    : filterName.includes("email")
    ? filterName.filter((f) => f !== "email")
    : filterName.includes("ecom")
    ? filterName.filter((f) => f !== "ecom")
    : filterName.includes("chart")
    ? filterName.filter((f) => f !== "chart")
    : filterName.includes("editor")
    ? filterName.filter((f) => f !== "editor")
    : filterName;
	
	const [darkMode, setDarkMode] = useState(false);
	const body = document.querySelector("body");
	if(darkMode){
		body.setAttribute("data-theme-version", "dark");
	}else{
		body.setAttribute("data-theme-version", "light");
	}
	
  return (
    <div className="header">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar"
                style={{ textTransform: "capitalize" }}
              >
                {finalName.join(" ").length === 0
                  ? "Dashboard"
                  : finalName.join(" ")}
              </div>
            </div>

            <ul className="navbar-nav header-right">
			<li className="nav-item dropdown notification_dropdown">
				<Link to={"#"} className={`nav-link bell dz-theme-mode ${darkMode ? 'active' : ''}`}>
					<i className="far fa-sun i-dark" onClick={() => setDarkMode(!darkMode)}></i>
					<i  className="far fa-moon  i-light" onClick={() => setDarkMode(!darkMode)}></i>
				</Link>
			</li>	
			
           
            
              <Dropdown className="nav-item dropdown header-profile ml-sm-4 ml-2">
                <Dropdown.Toggle
                  as="a"
                  to="#"
                  variant=""
                  className="nav-link i-false c-pointer"
                >
                  <div className="header-info">
                    <span className="text-black">
                      Hello, <strong>{admin.firstName}</strong>
                    </span>
                    <p className="fs-12 mb-0">Admin</p>
                  </div>
                  <img src={profile} width={20} alt="" />
                </Dropdown.Toggle>
                <Dropdown.Menu align="right" className="mt-2">
                  <LogoutPage logoutUser={logoutUser} />
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
