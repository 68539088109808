// NOTE: here should be loadingSpinner on className of inner div
function Spinner() {
    return (
        <div className="d-flex justify-content-center">
      <div className='loadingSpinnerContainer'>
        <div className='loadingSpinner'></div>
      </div>
      </div>
    )
  }
  
  export default Spinner
  