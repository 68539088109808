import { lazy, Suspense, useEffect } from 'react';
/// Components
import Index from './jsx/index';
import { connect, useDispatch, useSelector } from 'react-redux';
import {  Route, Switch, withRouter, BrowserRouter, Router } from 'react-router-dom';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./css/style.css";


const SignUp = lazy(() => import('./jsx/pages/Registration'));
const ForgotPassword = lazy(() => import('./jsx/pages/ForgotPassword'));
const Login = lazy(() => {
    return new Promise(resolve => {
    setTimeout(() => resolve(import('./jsx/pages/Login')), 500);
  });
  });

  toast.configure()
function App () {

    const { isLoading, admin } = useSelector((state) => state.auth)


    let routes = (  
      <Switch>
          <Route path='/' component={Login} />
          {/* <Route path='/page-register' component={SignUp} />
          <Route path='/page-forgot-password' component={ForgotPassword} /> */}
      </Switch>
  );
  if (admin) {
  return (
    <>
              <Suspense fallback={
                  <div id="preloader">
                      <div className="sk-three-bounce">
                          <div className="sk-child sk-bounce1"></div>
                          <div className="sk-child sk-bounce2"></div>
                          <div className="sk-child sk-bounce3"></div>
                      </div>
                  </div>  
                 }
              >
                  <Index />
              </Suspense>
          </>
      );

}else{
  return (
    <div className="vh-100">
              <Suspense fallback={
                  <div id="preloader">
                      <div className="sk-three-bounce">
                          <div className="sk-child sk-bounce1"></div>
                          <div className="sk-child sk-bounce2"></div>
                          <div className="sk-child sk-bounce3"></div>
                      </div>
                  </div>
                }
              >
                  {routes}
              </Suspense>
    </div>
  );
}
       
	

};

export default App; 
