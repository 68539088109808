import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getJobs } from '../../../features/jobSlice'
import { CSVLink } from 'react-csv'
import Spinner from '../../../Spinner'
import ReactPaginate from 'react-paginate'
import { deletJobs } from '../../../features/profileSlice'
import { toast } from 'react-toastify'
import JobDescription from './JobDescription'

const UnsafeComponent = ({ html }) => {
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

const Home = () => {
  const { jobs } = useSelector((state) => state.job)
  const [currentPage, setCurrentPage] = useState(1);
  const [hasExecuted, setExecuted] = useState(false)
  const dispatch = useDispatch()

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected)
  };

  const handleDeleteJob = (ids) => {
    dispatch(deletJobs(ids)).unwrap().then((resp) => {
      setExecuted(!hasExecuted)
      toast.success(resp.message)
    }).catch(toast.error)
  }


  useEffect(() => {
    dispatch(getJobs({ page: currentPage }))
  }, [dispatch, currentPage, hasExecuted])

  if (!jobs)
    return (
      <Spinner />
    )
  console.log(jobs)


  var x = 0;

  return (
    <>
      <h4 className="fs-20 text-black display-5 fw-bold">
        All Jobs
      </h4>

      <CSVLink data={jobs.data} className='btn btn-success float-right mb-3'>Export to CSV </CSVLink>

      <table className="table shadow-hover table-striped card-table w-100" >

        <thead>
          <tr>
            <th>S/N</th>
            <th>Job title</th>
            <th>Company</th>
            <th>Location</th>
            <th>Category</th>
            <th>Job Status</th>
            <th>Salary From</th>
            <th>Salary To</th>
            <th>Description</th>
            <th>Total Applications</th>
            <th>View</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {!jobs ? 'Loadind..' : jobs && jobs.data.map((items) => (
            <tr key={items.id}>
              <td>{++x}</td>
              <td>{items.jobTitle}</td>
              <td>{items.company}</td>
              <td>{items.jobLocation}</td>
              <td>{items.jobCategory}</td>
              <td>{items.jobStatus}</td>
              <td>{items.salaryFrom}</td>
              <td>{items.salaryTo}</td>
              <td width="100%">
                <JobDescription html={items.jobDescription} />
              </td>
              <td>{items.applicationCount}</td>
              <td>
                {items.applicationCount === 0 ? <i className='disabled fa fa-eye text-warning'></i> :
                  <a href={`/applicants?id=${items.id}`}><i className='fa fa-eye text-warning'></i></a>
                }
              </td>
              <td><i className='fa fa-trash text-danger' onClick={() => handleDeleteJob(items.id)}></i></td>
            </tr>
          ))}
        </tbody>




      </table>
      <ReactPaginate
        pageCount={jobs.totalPages}
        previousLabel={'Previous'}
        nextLabel={'Next'}
        breakLabel={'...'}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        onPageChange={handlePageChange}
        forcePage={currentPage}
        previousClassName={'page-item'}
        nextClassName={'page-item'}
        previousLinkClassName={'page-link'}
        nextLinkClassName={'page-link'}
        containerClassName={'pagination'}
        activeClassName={'active'}
        pageLinkClassName={'page-link'} />
    </>
  )
}

export default Home