import React, { useState } from 'react'
import { useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { getAllApplication, reject, addInterview, hired } from '../../../features/jobSlice'
import Spinner from '../../../Spinner'
import { CSVLink } from 'react-csv'
import ReactPaginate from 'react-paginate'

const Applications = () => {
  //get payload for applicationa
  const { applications } = useSelector((state) => state.job)
  var x = 0

  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1);
  //handle pagination
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1)
  };


  const [users, setUsers] = useState(null)
  const [usersId, setusersId] = useState(null)
  const [startTime, setTime] = useState('')
  const [dateIn, setDate] = useState('')
  const [address, setAddress] = useState('')
  const [notes, setNotes] = useState('')
  const [jobId, setJobId] = useState('')
  const [hasExecuted, setHasExecuted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);



  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (value, userId, jobId) => {
    setShow(true)
    setUsers(value)
    setusersId(userId)
    setJobId(jobId)
  }

  const sendInterview = (e) => {
    e.preventDefault()
    setShow(false)
    let data = {
      userEmail: users,
      userId: usersId,
      jobId: jobId,
      timeStart: startTime,
      dateIn: dateIn,
      address: address,
      notes: notes
    }
    dispatch(addInterview(data)).unwrap().then(() => {
      setHasExecuted(!hasExecuted)
      toast.success(`interview invite sent for ${users}`)

    }).catch(toast.error)

  }

  const rejects = (id) => {
    let data = {
      id: id
    }
    dispatch(reject(data)).unwrap().then(() => {
      toast.warning('Applicant status updated to rejected')
    }).catch(toast.error)
    setHasExecuted(!hasExecuted)
  }

  const hireApplicant = (id) => {
    dispatch(hired(id)).unwrap().then(() => {
      toast.warning('Applicant status updated to hired')
    }).catch(toast.error)
    setHasExecuted(!hasExecuted)
  }

  useEffect(() => {
    setIsLoading(true); // Set loading to true when fetching data
    dispatch(getAllApplication({ page: currentPage })); // Pass currentPage as a parameter to your action.
    // Scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Use 'smooth' for a smooth scroll animation
    });
    setIsLoading(false); // Set loading to true when fetching data
  }, [dispatch, show, hasExecuted, currentPage]);




  if (!applications)
    return (<Spinner />)

  console.log(applications)

  return (
    <>
      <h4 className="fs-20 text-black display-5 fw-bold">
        All Jobs
      </h4>

      <CSVLink data={applications.data.rows} className='btn btn-success float-right mb-3'>Export to CSV </CSVLink>
      <table className="table table-responsive shadow-hover table-striped card-table w-100" >

        <thead>
          <tr>
            <th>S/N</th>
            <th>Name</th>
            <th>Email</th>
            <th>Mode</th>
            <th>% Match</th>
            <th>Cover Letter</th>
            <th>Job Title</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {applications.data.rows.map((items) => (
            <tr key={items.id}>
              <td>{++x}</td>
              <td>{!items.ids ? '' : items.ids.lastName} {!items.ids ? '' : items.ids.firstName}</td>
              <td>{!items.ids ? '' : items.ids.email}</td>
              <td>{!items.ids ? '' : items.applicationMode}</td>
              <td>{!items.ids ? '' : items.percentMatch}%</td>
              <td>{!items.ids ? '' : items.coverLetter}</td>
              <td>{!items.jids ? '' : items.jids.jobTitle}</td>
              <td>{items.applicantStatus}</td>
              <td>
                <div className='btn-group'>
                  <i className='fa fa-times-circle mx-2 my-2 text-danger' onClick={() => rejects(items.id)}></i>
                  {items.applicationMode === 'withProfile' ?
                    <a href={`/userProfile?id=${items.userId}`} className='mx-2 my-2'><i className='fa fa-eye text-warning'></i></a>
                    : items.applicationMode === 'withCv' ? <a href={`/userCv?userId=${items.id}`} className='fa fa-file-text mx-2 my-2 text-warning'></a> : ''}

                  {items.applicantStatus === 'Interview' ? <i className='fa fa-check-square text-success mx-2 my-2' onClick={() => hireApplicant(items.id)}></i> : items.applicantStatus === 'pending' ?
                    <i className='fa fa-calendar-times text-info mx-2 my-2' onClick={() => handleShow(!items.ids ? '' : items.ids.email, items.userId, items.jobId)}></i> : ''
                  }
                </div>

              </td>
            </tr>
          ))}
        </tbody>




      </table>


      <ReactPaginate
        pageCount={applications.totalPages}
        previousLabel={'Previous'}
        nextLabel={'Next'}
        breakLabel={'...'}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        onPageChange={handlePageChange}
        forcePage={currentPage - 1}
        previousClassName={'page-item'}
        nextClassName={'page-item'}
        previousLinkClassName={'page-link'}
        nextLinkClassName={'page-link'}
        containerClassName={'pagination'}
        activeClassName={'custom-active-page'} // Specify your custom class here
        pageLinkClassName={'page-link'}
      >
        {isLoading && <Spinner />} {/* Place the loading spinner inside ReactPaginate */}
      </ReactPaginate>


      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Schedule Interview for {users} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>

            <div className='col-md-6 col-sm-12'>
              <div className='form-group'>
                <input type="date" className='form-control' onChange={(e) => setDate(e.target.value)} />

              </div>
            </div>

            <div className='col-md-6 col-sm-12'>
              <div className='form-group'>
                <input type="time" className='form-control' onChange={(e) => setTime(e.target.value)} />

              </div>
            </div>

            <div className='col-md-6 col-sm-12'>
              <div className='form-group'>
                <input type="text" className='form-control' placeholder='Interview link or Address' onChange={(e) => setAddress(e.target.value)} />
              </div>
            </div>

            <div className='col-md-6 col-sm-12'>
              <div className='form-group'>
                <input type="text" className='form-control' placeholder='Extra Information' onChange={(e) => setNotes(e.target.value)} />
              </div>
            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={sendInterview}>
            Schedule
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Applications