import {createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import axios from "axios"
import { extractErrorMessage } from "../utils"

//get main link
const url = process.env.REACT_APP_BACKEND

// Get user from localstorage
const admin = JSON.parse(localStorage.getItem('admin'))


const initialState = {
  admin: admin ? admin : null,
  isLoading: false,
  isAdmin: false,
}

//login 
export const loginAdmin = createAsyncThunk(
  'admin/login',
  async (data, thunkAPI) => {
    try{
       const response = await axios.post(`${url}/admin/adminLogin`, data)
    if (response.data) {
      localStorage.setItem('admin', JSON.stringify(response.data))
    }
    return response.data
  }catch(error){
    return thunkAPI.rejectWithValue(extractErrorMessage(error))
  }
   
  });

  export const addAdmin = createAsyncThunk(
    'admin/signup',
    async(data, thunkAPI) => {
      try{
        const response = await axios.post(`${url}/auth/addAdmin`, data)
        return response.data

      }catch(error){
        return thunkAPI.rejectWithValue(error)
        
      }
    }
  )


// Logout user
export const logout = createAsyncThunk('auth/logout', async () => {
  await localStorage.removeItem('user')
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers:  (builder) => {
    (builder)
  
  .addCase(loginAdmin.fulfilled, (state, action) => {
    state.isAdmin = true;
    state.admin = action.payload;
    state.error = null;
  })
  .addCase(loginAdmin.pending, (state, action) => {
    state.isAdmin = false;
    state.admin = null;
    state.error = action.payload;
  })
  .addCase(loginAdmin.rejected, (state) => {
    state.isAdmin = false;
    state.admin = null;
    state.error = null;
  })
  .addCase(logout.fulfilled, (state)=>{
    state.admin = null;
  })

  },
})

// export const { loginSuccess, loginFailure, logout } = authSlice.actions;

// export const login = (data) => async (dispatch) => {  
//     try{
//       const response = await axios.post(`${url}/auth/login`, data)
//       if (response.data) {
//         localStorage.setItem('user', JSON.stringify(response.data))
//       }
//       const user = await response.data
//       dispatch(loginSuccess(user))
//     }catch(error){
//       dispatch(loginFailure(error.message));
//     }
  
// }

// export const googlLogin = (data) => async (dispatch) => {
//     try{
   
//         await localStorage.setItem('user', JSON.stringify(data))
      
//       const user = data.json
//       dispatch(loginSuccess(user))
      
//     }catch(error){
//       dispatch(loginFailure(error.message));
//     }
  
// }




export const selectisLoggedIn = (state) => state.auth.isLoggedIn;
export default authSlice.reducer


