import React, { useState } from 'react';

const JobDescription = ({ html }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const renderContent = () => {
    if (expanded) {
      return <div dangerouslySetInnerHTML={{ __html: html }} />;
    } else {
      // Limit the visible content to a certain length, e.g., 200 characters
      const truncatedContent = html.length > 200 ? html.slice(0, 200) + '...' : html;
      return <div dangerouslySetInnerHTML={{ __html: truncatedContent }} />;
    }
  };

  return (
    <td width="100%">
      {renderContent()}
      {html.length > 200 && (
        <button onClick={toggleExpanded} className='btn btn-sm btn-outline-danger broder-none'>
          {expanded ? 'Read Less' : 'Read More'}
        </button>
      )}
    </td>
  );
};

export default JobDescription;
