import React, { useEffect } from 'react'
import { Document, Page } from 'react-pdf'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCv } from '../../../features/jobSlice'
import Spinner from '../../../Spinner'


const UserCv = () => {
    const queryParams = new URLSearchParams(window.location.search)
    const userId = queryParams.get("userId")

    const {cvPdf} = useSelector((state)=>state.job)
    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(fetchCv(userId))
    },[dispatch])

    console.log(cvPdf)

    if(!cvPdf)
    return(
        <Spinner />
    )

  return (
    <div> 
        <iframe src={cvPdf} style={{ minWidth:'100%', minHeight:'calc(100vh)' }} />
    {/* <Document file={{url:cvPdf}}>
  </Document> */}
  </div>
  )
}

export default UserCv