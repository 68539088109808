import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../features/authSlice'
import jobReducer from '../features/jobSlice'
import profileReducer from '../features/profileSlice'
import chatReducer from '../features/chatSlice'





export const store = configureStore({
  reducer: {
   auth: authReducer,
   job:jobReducer,
   profile:profileReducer,
   chat:chatReducer
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: false,
  }),
})
