import React, { useState } from 'react'
/// React router dom
import {Switch, Route } from 'react-router-dom'
/// Css
import './index.css'
import './chart.css'
import './step.css'

/// Layout
import Nav from './layouts/nav'
import Footer from './layouts/Footer'

/// Dashboard
//import Home from './components/Dashboard/Home'
//import MyWallet from './components/Dashboard/MyWallet'
//import Invoices from './components/Dashboard/Invoices'
//import CardsCenter from './components/Dashboard/CardsCenter'
//import Transactions from './components/Dashboard/Transactions'
//import TransactionsDetails from './components/Dashboard/TransactionsDetails'

/// Dashboard
import Home from "./components/Dashboard/Home";
import DashboardDark from "./components/Dashboard/DashboardDark";
import Wallet from "./components/Dashboard/Wallet";
import CoinDetails from "./components/Dashboard/CoinDetails";

//jobs
import HomeJob from "./components/Jobs/Home"
import AddJobs from './components/Jobs/AddJobs'

//Applicants
import Applicants from './components/Applicants/Applicants'
import MainChat from './components/chatBox/MainChat'
import UserProfile from './components/Applicants/UserProfile'
import UserCv from './components/Applicants/UserCv'
import Schedule from './components/Interview/Schedule'
import AdminCvParsing from './components/CvParsing/AdminCvParsing'
import AdminCV from './components/CvParsing/AdminCV'
import Applications from './components/Applicants/Applications'
import AddAdmin from './components/Users/AddAdmin'
import AllUsers from './components/PlatformUser/AllUsers'
import Category from './components/Jobs/Category'
import Feedbacks from './components/Feedbacks/Feedbacks'




const Markup = () => {
  let path = window.location.pathname
  path = path.split('/')
  path = path[path.length - 1]
  let pagePath = path.split('-').includes('page')
  const [activeEvent, setActiveEvent] = useState(!path)

  const routes = [
    /// Dashboard
    { url: '', component: Home },
    { url: 'dashboard', component: Home },
    { url: 'dashboard-dark', component: DashboardDark },
    { url: "my-wallet", component: Wallet },
    { url: "coin-details", component: CoinDetails },

    //jobs
    {url:'jobs', component: HomeJob},
    {url:'add-jobs', component: AddJobs},
    {url:'category', component:Category},

    //applicants
    {url:'applicants', component:Applicants},
    {url:'userProfile', component:UserProfile},
    {url:'userCv', component:UserCv},
    {url:'applications', component:Applications},

    //interview
    {url:'schedules', component:Schedule},

    //chats
    {url: 'chats', component:MainChat},

    //cv parsing 
    {url: 'parse-cv', component:AdminCvParsing},
    {url: 'adminCv', component:AdminCV},

    //Users
    {url: 'add-admin', component:AddAdmin},

    //Platform users
    {url: 'all-users', component:AllUsers},

    //feedbacks
    {url: 'feedbacks', component:Feedbacks}
   
    
  ]

  return (
       <> 
          <div
            id={`${!pagePath ? 'main-wrapper' : ''}`}
            className={`${!pagePath ? 'show' : 'mh100vh'}`}
          >
            {!pagePath && (
              <Nav
                onClick={() => setActiveEvent(!activeEvent)}
                activeEvent={activeEvent}
                onClick2={() => setActiveEvent(false)}
                onClick3={() => setActiveEvent(true)}
              />
            )}
            <div
              className={` ${!path && activeEvent ? 'rightside-event' : ''} ${
                !pagePath ? 'content-body' : ''
              }`}
            >
              <div
                className={`${!pagePath ? 'container-fluid' : ''}`}
                style={{ minHeight: window.screen.height - 60 }}
              >
                <Switch>
                  {routes.map((data, i) => (
                    <Route
                      key={i}
                      exact
                      path={`/${data.url}`}
                      component={data.component}
                    />
                  ))}
                </Switch>
              </div>
            </div>
            {!pagePath && <Footer />}
          </div>
 
       </>
  )
}

export default Markup
