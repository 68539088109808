import {createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import axios from "axios"
import { extractErrorMessage } from "../utils"

//get main link
const url = process.env.REACT_APP_BACKEND

const initialState ={
    jobs:null,
    category:null,
    isLoading:null,
    applicants:null,
    cvPdf:null,
    interview:null,
    myInterview:null,
    adminCv:null,
    cvAdmin:null,
    applications:null,
    users:null,
    rejected:null,
    hired:null,
    inter:null,
    profile:null,
    admins:null,
    
}
//delete admin
export const deleteCategory = createAsyncThunk(
  'admin/deleteCategory',
  async(id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.admin.token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const response = await axios.delete(`${url}/admin/deleteCategory/${id}`, config)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)
//delete admin
export const deleteAdmin = createAsyncThunk(
  'admin/deleteAdmin',
  async(id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.admin.token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const response = await axios.delete(`${url}/admin/deleteAdmin/${id}`, config)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)
//get all admin
export const allAdmin = createAsyncThunk(
  'admin/allAdmin',
  async (params, thunkAPI) => {
    const { page, pageSize } = Object.assign({ page: 1, pageSize: 50 }, params);
    try {
      const token = thunkAPI.getState().auth.admin.token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const response = await axios.get(`${url}/admin/getAllAdmin?page=${page}&pageSize=${pageSize}`, config)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)
//add an admin
export const addAdmin = createAsyncThunk(
  'admin/addAdmin',
  async(data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.admin.token
  
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const response = await axios.post(`${url}/admin/addAdmin`, data, config)
      return response.data
      
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)
//add a category
export const addCategory = createAsyncThunk(
  'admin/addCategory',
  async(data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.admin.token
  
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const response = await axios.post(`${url}/admin/addCategory`, data, config)
      return response.data
      
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)
//update Admin cv passed rejected
export const rejectAdmin = createAsyncThunk(
  'admin/rejectAdmin',
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.admin.token
  
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const response = await axios.patch(`${url}/admin/rejectAdmin/${id}`, config)
      return response.data

    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  });
//update Admin cv passed hired
export const hiredAdmin = createAsyncThunk(
  'admin/hiredAdmin',
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.admin.token
  
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const response = await axios.patch(`${url}/admin/hiredAdmin/${id}`, config)
      return response.data

    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  });
//hired
export const hired = createAsyncThunk(
  'admin/hired',
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.admin.token
  
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const response = await axios.patch(`${url}/admin/hired/${id}`, config)
      return response.data

    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  });
//get user profile
export const getUserProfile = createAsyncThunk(
  'users/userprofile',
  async (email, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.admin.token
  
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const response = await axios.get(`${url}/users/getUserWithEmail/${email}`, config)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }

  });
//get hired
export const getHired = createAsyncThunk(
  'admin/getHired',
  async(_, thunkAPI)=>{
    try {
      const token = thunkAPI.getState().auth.admin.token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        }, 
      }
      const response = await axios.get(`${url}/admin/fetchTrends/hired`, config)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)

//get hired
export const getInter = createAsyncThunk(
  'admin/getInter',
  async(_, thunkAPI)=>{
    try {
      const token = thunkAPI.getState().auth.admin.token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        }, 
      }
      const response = await axios.get(`${url}/admin/fetchTrends/interview`, config)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)

//get rejected trends
export const getRejected= createAsyncThunk(
  'admin/getRejected',
  async(_, thunkAPI)=>{
    try {
      const token = thunkAPI.getState().auth.admin.token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        }, 
      }
      const response = await axios.get(`${url}/admin/fetchTrends/rejected`, config)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)

export const getAllUser = createAsyncThunk(
  'admin/getAllUser',
  async (params, thunkAPI) => {
    const { page, pageSize } = Object.assign({ page: 1, pageSize: 50 }, params);
    try {
      const token = thunkAPI.getState().auth.admin.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(`${url}/admin/getAllUsers?page=${page}&pageSize=${pageSize}`, config);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);
//get all applicants
export const getAllApplication = createAsyncThunk(
  'admin/getApplications',
  async (params, thunkAPI) => {
    const { page, pageSize } = Object.assign({ page: 1, pageSize: 50 }, params);
    try {
      const token = thunkAPI.getState().auth.admin.token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        }, 
      }
      const response = await axios.get(`${url}/admin/getAllApplicants?page=${page}&pageSize=${pageSize}`, config)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)
//get admin cv
export const getAdminCv = createAsyncThunk(
  'admin/getAdminCv',
  async (params, thunkAPI) => {
    const { page, pageSize } = Object.assign({ page: 1, pageSize: 50 }, params);
    try {
      const token = thunkAPI.getState().auth.admin.token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        }, 
      }
      const response = await axios.get(`${url}/admin/getAdminCv?page=${page}&pageSize=${pageSize}`, config)
      return response.data

    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)
//parse cv from admin
export const parseCv = createAsyncThunk(
  'admin/parseCv',
  async(data, thunkAPI)=>{
    try {
      const token = thunkAPI.getState().auth.admin.token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        }, 
      }
      const response = await axios.post(`${url}/admin/parseCv`, data, config)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)
//single Interview
export const singleInterview = createAsyncThunk(
  'admin/singleInterview',
  async(id, thunkAPI)=>{
    try {
      const token = thunkAPI.getState().auth.admin.token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        }, 
      }
      const response = await axios.get(`${url}/admin/singleInterview/${id}`, config)
      return response.data
      
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)
//edit interview
export const editInterview = createAsyncThunk(
  'admin/editInterview',
  async(data, thunkAPI)=>{
    try {
      const token = thunkAPI.getState().auth.admin.token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        }, 
      }
      const response = await axios.post(`${url}/admin/editInterview`, data, config)
      return response.data
      
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)

//cancel an interview
export const cancelInterview = createAsyncThunk(
  'admin/cancelInterview',
  async(data, thunkAPI)=>{
    try {
      const token = thunkAPI.getState().auth.admin.token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        }, 
      }
      const response = await axios.post(`${url}/admin/cancelInterview`, data, config)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)



// get all interview schedule
export const getInterview = createAsyncThunk(
  'admin/getInterview',
  async (params, thunkAPI) => {
    const { page, pageSize } = Object.assign({ page: 1, pageSize: 50 }, params);
    try {
      const token = thunkAPI.getState().auth.admin.token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        }, 
      }
      const response = await axios.get(`${url}/admin/getAllInterview?page=${page}&pageSize=${pageSize}`, config)
      return response.data
      
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)
//addinterview
export const addInterview = createAsyncThunk(
  'admin/addInterview',
  async(data, thunkAPI)=>{
    try {
      const token = thunkAPI.getState().auth.admin.token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        }, 
      }
      const response = await axios.post(`${url}/admin/addInterview`, data, config)
      return response.data
    } catch (error) {

      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)

//edit interview for admin cv
export const updateInterviewAdmin = createAsyncThunk(
  'admin/updateInterviewAdmin',
  async(data, thunkAPI)=>{
    try {
      const token = thunkAPI.getState().auth.admin.token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        }, 
      }
      const response = await axios.post(`${url}/admin/updateAdminJobStatus`, data, config)
      return response.data
    } catch (error) {

      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)


//reject a candidate
export const reject = createAsyncThunk(
  'admin/reject',
  async(data, thunkAPI)=>{
    try {
      const token = thunkAPI.getState().auth.admin.token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        }, 
      }
      const response = await axios.post(`${url}/admin/reject`, data, config)
      return response.data
    } catch (error) {

      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
  }
)
//fetch cv
export const fetchCv = createAsyncThunk(
  'admin/fetchCv',
  async(userId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.admin.token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        }, 
      }
      const response = await axios.get(`${url}/admin/fetchCv/${userId}`, { responseType: "arraybuffer" } )
      const blob = new Blob([response.data], { type: "application/pdf" });
      return URL.createObjectURL(blob);
      
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }

  }
)


//fetch admin parsed cv
export const fetchAdminCv = createAsyncThunk(
  'admin/fetchAdminCv',
  async(userId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.admin.token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        }, 
      }
      const response = await axios.get(`${url}/admin/fetchAdminCv/${userId}`, { responseType: "arraybuffer" })
      const blob = new Blob([response.data], { type: "application/pdf" });
      return URL.createObjectURL(blob);
      
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }

  }
)


//get all Applicants
export const getApplicants = createAsyncThunk(
  'admin/getApplicants',
  async ({params, jobId}, thunkAPI) => {
    const { page, pageSize } = Object.assign({ page: 1, pageSize: 50 }, params);
    try {
      const token = thunkAPI.getState().auth.admin.token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        }, 
      }
  const response = await axios.get(`${url}/admin/getApplicant/${jobId}?page=${page}&pageSize=${pageSize}`, config)
    return response.data.data
  }catch(error){
    return thunkAPI.rejectWithValue(extractErrorMessage(error))
  }
   
  });

//get all categories
export const getCategory = createAsyncThunk(
    'admin/getCategory',
      async (params, thunkAPI) => {
      const { page, pageSize } = Object.assign({ page: 1, pageSize: 50 }, params);
      try{
        const token = thunkAPI.getState().auth.admin.token
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          }, 
        }
    const response = await axios.get(`${url}/admin/getCategory?page=${page}&pageSize=${pageSize}`, config)
      return response.data
    }catch(error){
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
     
    });


    //get all Jobs
export const getJobs = createAsyncThunk(
    'admin/getJobs',
    async (params, thunkAPI) => {
      const { page, pageSize } = Object.assign({ page: 1, pageSize: 50 }, params);
      try{
        const token = thunkAPI.getState().auth.admin.token
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          }, 
        }
    const response = await axios.get(`${url}/admin/getJobs?page=${page}&pageSize=${pageSize}`, config)
      return response.data
    }catch(error){
      return thunkAPI.rejectWithValue(extractErrorMessage(error))
    }
     
    });
//add jobs
    export const addJobs = createAsyncThunk(
        'admin/addJobs',
        async(data, thunkAPI) => {
            try{
                const token = thunkAPI.getState().auth.admin.token
                const config = {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  }, 
                }
            const response = await axios.post(`${url}/admin/addJobs`, data, config)
              return response.data
            }catch(error){
              return thunkAPI.rejectWithValue(extractErrorMessage(error))
            }
        }
    )

    

    export const jobSlice = createSlice({
        name: 'job',
        initialState,
        reducers: {
          // setPage(state, action) {
          //   state.page = action.payload;
          // },
          // setPageSize(state, action) {
          //   state.pageSize = action.payload;
          // },
        },
        extraReducers:  (builder) => {
          (builder)
        
        .addCase(getCategory.fulfilled, (state, action) => {
          state.isLoading = true;
          state.category = action.payload;
          state.error = null;
        })
        .addCase(getCategory.pending, (state, action) => {
          state.isLoading = false;
          state.category = null;
          state.error = action.payload;
        })
        .addCase(getCategory.rejected, (state) => {
          state.isLoading = false;
          state.category = null;
          state.error = null;
        })
        .addCase(getJobs.fulfilled, (state, action)=>{
            state.jobs = action.payload
            state.error=null
        })
        .addCase(getJobs.pending, (state, action) => {
            state.isLoading = false;
            state.jobs = null;
            state.error = action.payload;
          })
          .addCase(getJobs.rejected, (state) => {
            state.isLoading = false;
            state.jobs = null;
            state.error = null;
          })
          .addCase(getApplicants.fulfilled, (state, action)=>{
            state.applicants = action.payload
          })
          .addCase(fetchCv.fulfilled, (state, action)=>{
            state.cvPdf = action.payload
          })
          .addCase(getInterview.fulfilled, (state, action)=>{
            state.interview = action.payload
          })
          .addCase(singleInterview.fulfilled, (state, action)=>{
            state.myInterview = action.payload
          })
          .addCase(getAdminCv.fulfilled, (state, action)=>{
            state.adminCv = action.payload
          })
          .addCase(fetchAdminCv.fulfilled, (state, action)=>{
            state.cvAdmin = action.payload
          })
          .addCase(getAllApplication.fulfilled, (state, action)=>{
            state.applications = action.payload
          })
          .addCase(getAllUser.fulfilled, (state, action)=>{
            state.users = action.payload
          })
          .addCase(getHired.fulfilled, (state, action)=>{
            state.hired = action.payload
          })
          .addCase(getInter.fulfilled, (state, action)=>{
            state.inter = action.payload
          })
          .addCase(getRejected.fulfilled, (state, action)=>{
            state.rejected = action.payload
          })
          .addCase(getUserProfile.fulfilled, (state, action)=>{
            state.profile = action.payload
          })
          .addCase(allAdmin.fulfilled, (state, action)=> {
            state.admins = action.payload
          })
      
        },
      })
      //export const { setPage, setPageSize } = jobSlice.actions;

      export default jobSlice.reducer