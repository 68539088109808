import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addAdmin, allAdmin, deleteAdmin } from '../../../features/jobSlice'
import { toast } from 'react-toastify'
import { Button, Modal } from 'react-bootstrap'
import Spinner from '../../../Spinner'
import ReactPaginate from 'react-paginate'

const AddAdmin = () => {
    const {admins} = useSelector((state)=>state.job)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [adminType, setAdminType] = useState('')
    const [hasExecuted, setHasExecuted] = useState(false);
    const [show, setShow] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);

    const handlePageChange = ({ selected }) => {
     setCurrentPage(selected)
   };

    let x=0

    const handleClose = () => setShow(false)
    const handleOpen = () => setShow(true)

    const dispatch = useDispatch()

    const handleAdmin = () =>{
        if(!firstName || !lastName || !password || !email || !adminType){
            toast.warning("fill are required fields")
        }
        else{
           let data = {
            firstName,
            lastName,
            password,
            email,
            adminType
        }
        dispatch(addAdmin(data)).unwrap().then(()=>{
            toast.success('Admin added and email sent for notification')
        }).catch(toast.error) 
        setHasExecuted(!hasExecuted)
        }
        
    }

    const handleDelete =(id)=>{
        dispatch(deleteAdmin(id)).unwrap().then(()=>{
            toast.success('User deleted')
        }).catch(toast.error) 
        setHasExecuted(!hasExecuted)
    }

    useEffect(()=>{
        dispatch(allAdmin({page:currentPage}))
        
    },[dispatch, hasExecuted])

    if(!admins)
    return <Spinner />

  return (
    <>
<div>
        <Button className='btn btn-warning mb-3 float-right' onClick={handleOpen}>Add an admin</Button> 
    </div>
       
        <table className="table shadow-hover table-striped card-table w-100" >
                                       
                                       <thead>
                                        <tr>
                                          <th>S/N</th>
                                          <th>Name</th>
                                          <th>Email</th>
                                          <th>Admin Type</th>
                                          </tr>
                                       </thead>
                                       <tbody>
                                       {admins.data.count === 0 ? 'No data available' : admins.data.rows.map((items)=>(
                                            <tr key={items.id}>
                                              <td>{++x}</td>
                                              <td>{items.firstName}{' '}{items.lastName}</td>
                                              <td>{items.email}</td>
                                              <td>{items.adminType == 1 ? 'Super Admin' : items.adminType == 0 ? 'Admin' : ''}</td>
                                              <td>
                                              <div className='btn-group'>
                                              <i className='fa fa-times-circle mx-2 my-2 text-danger' onClick={()=>handleDelete(items.id)}></i>
                                              </div>
                                              </td>
                                          </tr>
                                        ))}
                                        </tbody>
                                        </table>
                                        <ReactPaginate
                pageCount={admins.totalPages}
                previousLabel={'Previous'}
                nextLabel={'Next'}
                breakLabel={'...'}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                onPageChange={handlePageChange}
                forcePage={currentPage}
                previousClassName={'page-item'}
                nextClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextLinkClassName={'page-link'}
                containerClassName={'pagination'}
                activeClassName={'active'}
                pageLinkClassName={'page-link'} />
                                        <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Admin user </Modal.Title>
        </Modal.Header>
        <Modal.Body>
<div className='row'>
  <div className='col-xl-12 col-lg-12'>
    <div className='card'>
      <div className='card-body'>
        <div className='form-group mb-3'>
            <label className='form-label text-muted'>Admin firstName</label>
            <input type="text" className='form-control' placeholder='First Name' onChange={(e)=>setFirstName(e.target.value)}  />
        </div>

        <div className='form-group mb-3'>
            <label className='form-label text-muted'>Admin lastName</label>
            <input type="text" className='form-control' placeholder='Last Name' onChange={(e)=>setLastName(e.target.value)}  />
        </div>

        <div className='form-group mb-3'>
            <label className='form-label text-muted'>Email</label>
            <input type="email" className='form-control' placeholder='Email Address'  onChange={(e)=>setEmail(e.target.value)} />
        </div>

        <div className='form-group mb-3'>
            <label className='form-label text-muted'>Password</label>
            <input type="text" className='form-control' placeholder='Password' onChange={(e)=>setPassword(e.target.value)} />
        </div>

        <div className='form-group mb-3'>
            <label className='form-label text-muted'>Admin Category</label>
            <select className='form-select form-control' onChange={(e)=>setAdminType(e.target.value)} >
                <option value={' '} className='disabled'>Select admin type</option>
                <option value="1">Super Admin</option>
                <option value="0">Regular Admin</option>

            </select>
        </div>
       

        </div>
        </div>
        </div>
        </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleAdmin}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>    
    </>
  )
}

export default AddAdmin