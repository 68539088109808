import React, { Fragment, useEffect, useState } from 'react'
import PageTItle from '../../layouts/PageTitle'
import { getCategory, addJobs } from '../../../features/jobSlice'
import { useDispatch, useSelector } from 'react-redux'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';

const AddJobs = () => {

    const { category } = useSelector(state=>state.job)
    

    const dispatch = useDispatch()

    const [jobTitle, setJobTitle] = useState('')
    const [jobLocation, setJobLocation] = useState('')
    const [experience, setExp] = useState('')
    const [salaryFrom, setSalaryFrom] = useState('')
    const [salaryTo, setSalaryTo] = useState('')
    const [jobCategory, setJobCategory] = useState('')
    const[company, setCompany] = useState('')
    const[jobType, setJobtype] = useState('')
    const [jobStatus, setStatus] = useState('')
    const [keywords, setKeywords] = useState('')
    const [jobDescription, setDescrip] = useState('')

    const submitJobs = (e) =>{
        e.preventDefault()
        let data={
            jobTitle,
            jobLocation,
            experience,
            salaryFrom,
            salaryTo,
            jobCategory,
            company,
            jobType,
            jobStatus,
            keywords,
            jobDescription
        }
        dispatch(addJobs(data)).unwrap().then((response)=>{
            toast.success("Job Posted Successfully")
            setJobTitle('')
            setJobLocation('')
            setExp('')
            setSalaryFrom('')
            setSalaryTo('')
            setJobCategory('')
            setCompany('')
            setJobtype('')
            setStatus('')
            setKeywords('')
            setDescrip('')
        }).catch(toast.error)
    } 

    
    //fetch categories
    useEffect(()=>{
        dispatch(getCategory())
    },[dispatch])

   

    
    
  return (
    <Fragment>
    <PageTItle activeMenu='Add Jobs' motherMenu='Jobs' />

    <div className='row'>
      <div className='col-xl-8 col-lg-8'>
        <div className='card'>
          <div className='card-header'>
            <h4 className='card-title'>Post a job</h4>
          </div>
          <div className='card-body'>
            <div className='form-group mb-3'>
                <label className='form-label text-muted'>Job Title</label>
                <input type="text" className='form-control' value={jobTitle} placeholder='Job Title' onChange={(e)=>setJobTitle(e.target.value)} />
            </div>

            <div className='form-group mb-3'>
                <label className='form-label text-muted'>Job Location</label>
                <input type="text" className='form-control' value={jobLocation} placeholder='Job Location' onChange={(e)=>setJobLocation(e.target.value)} />
            </div>

            <div className='form-group mb-3'>
                <label className='form-label text-muted'>Years of experience</label>
                <input type="number" className='form-control' value={experience} placeholder='Years of experience' onChange={(e)=>setExp(e.target.value)} />
            </div>
            <div className='form-group mb-3'>
                <label className='form-label text-muted'>salaryFrom</label>
                <input type="number" className='form-control' value={salaryFrom} placeholder='Salary From' onChange={(e)=>setSalaryFrom(e.target.value)} />
            </div>
            <div className='form-group mb-3'>
                <label className='form-label text-muted'>SalaryTo</label>
                <input type="number" className='form-control' value={salaryTo} placeholder='Salary To' onChange={(e)=>setSalaryTo(e.target.value)} />
            </div>

            <div className='form-group mb-3'>
                <label className='form-label text-muted'>Job Category</label>
                <select className='form-select form-control' onChange={(e)=>setJobCategory(e.target.value)}>
                    <option defaultValue={''} >Select a category</option>
                    {!category?'loading....' :category.data.rows.map(items => (
                        <option key={items.id}>{items.category}</option>
                    ))}
                </select>
            </div>
            <div className='form-group mb-3'>
                <label className='form-label text-muted'>Company</label>
                <input type="text" className='form-control' value={company} placeholder='Company' onChange={(e)=>setCompany(e.target.value)} />
            </div>

            <div className='form-group mb-3'>
                <label className='form-label text-muted'>Job type</label>
                <input type="text" className='form-control' value={jobType} placeholder='Job type e.g Remote, hybrid' onChange={(e)=>setJobtype(e.target.value)} />
            </div>

            <div className='form-group mb-3'>
                <label className='form-label text-muted'>Job Status</label>
                <select className='form-select form-control' onChange={(e)=>setStatus(e.target.value)}>
                    <option defaultValue={''}>Select job status</option>
                    <option value="1">Active</option>
                    <option value="0">Expired</option>
                </select>
            </div>

            <div className='form-group mb-3'>
                <label className='form-label text-muted'>Keywords <small className='text-danger'>Separate key words with comma ,</small></label> 
                <input type="text" className='form-control' value={keywords} placeholder='Keywords' onChange={(e)=>setKeywords(e.target.value)} />
            </div>

            <div className='form-group mb-3'>
                <label className='form-label text-muted'>Job description</label> 
                <ReactQuill theme="snow" value={jobDescription} onChange={setDescrip} />
            </div>
            
            <div className='form-group mb-3'>
               <button className='btn btn-danger btn-lg btn-block' onClick={submitJobs}>Post Job</button>
            </div>



            </div>
            </div>
            </div>
            </div>
            </Fragment>
  )
}

export default AddJobs



